import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileUpload } from 'primereact/fileupload';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../../core/axiosInstance';
import { BackgroundContainer, Gallery } from './Background.style';
import { setLoading } from '../../../features/loading/loadingSlice';
import { useAppDispatch } from '../../../core/hooks';
import { BodyContent } from '../Main/Main.style';

const Background = () => {
  const formData = new FormData();
  const [images, setimages] = useState([]);
  const [selected, setselected] = useState(-1);
  const [up, setUp] = useState(0);
  const [busy, setbusy] = useState(false);
  let fileUpload: FileUpload | null;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /* Call all uploaded background-images in the database and store them in the images variable*/
  useEffect(() => {
    axiosInstance.get('/dashboard/background')
      .then((res: any) => {
        dispatch(setLoading(false))
        setimages(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false))
      });
  }, [up]);

  /* the custome upload function for the prime-uploader component */
  const myUploader =async (event: any) => {
    console.log('event.files',event.files);
    for (let i = 0; i<event.files.length;i++) {
        let element = event.files[i];
        console.log({element},i);
        // fd.append(key,element);
        formData.append('name', event.files[i].name);
        formData.append('type_id', '1');
        formData.append('content[]', event.files[i]);
        setbusy(true)
      await  axiosInstance.post('/dashboard/background', formData)
          // .then((res: any) => {
            setUp(up + i+1);
            setbusy(false);
          //   console.log(res);
          formData.delete('name');
          formData.delete('type_id');
          formData.delete('content[]');
          // })
          // .catch((err) => {
          //   setbusy(false);
          // });
        }
        
        event.files.length = 0
        console.log("end", fileUpload);
        // window.location.reload()
        fileUpload?.clear();

  };

  /* delete certain image from the list of images in Gallery component */
  const deleteBG = async (id: number, i: number) => {
    dispatch(setLoading(true));
    await axiosInstance.delete(`/dashboard/background/${id}`)
      .then((res: any) => {
        let newImages = [...images];
        newImages.splice(i, 1);
        setimages(newImages);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  /* send the selected image to the back-end then navigates to the main page to enable preview */
  const submitBG = async () => {
    if (selected >= 0) {
      console.log('selected:', selected);
      
      const formUpdate = new FormData();
      formUpdate.append('is_active', '1');
      formUpdate.append('_method', 'put');
      dispatch(setLoading(true));
      await axiosInstance.post(`/dashboard/background/${selected}`, formUpdate)
        .then((res: any) => {
          toast.success('The image has been set', {
            position: toast.POSITION.TOP_CENTER,
          })
          dispatch(setLoading(false));
          navigate('/createAnnounce');  /* navigates to the main page to enable preview */
        }).catch((err) => {
          dispatch(setLoading(false));
        })
    }
  }

  return (
    <BodyContent>
      <BackgroundContainer>
        <button onClick={submitBG} className='sbt-btn' disabled={!(selected >= 0)} title="Choose image first" > Submit </button>
        {/* <h1> </h1> */}
        <div className="p-grid">
          {/* ------------- Left section ----------- */}
          <div className="p-col-12 p-lg-6">
            <h4>Upload Background</h4>
            {/* FileUpload is a prime Component for browse and upload has many custome attributes */}
            <FileUpload name="demo[]" customUpload uploadHandler={myUploader} multiple={false}
              accept="image/*" maxFileSize={2000000} disabled={busy} ref={(el) => fileUpload = el} />
          </div>
          {/* ------------- right section ----------- */}
          <Gallery className="p-col-12 p-lg-6">
            <h4>Select the Main Background From This Gallery</h4>
            {/* ----------- list of uploaded images ---------- */}
            <div className="imageContainer">
              {images.map((img: any, i) => {
                return (
                  <div className="imageElement" key={i} onClick={() => { setselected(img.id); }}>
                    {/* delete icon in the top right */}
                    <FontAwesomeIcon icon={faTrash} className="favIcon delete"
                      onClick={() => { deleteBG(img.id, i); }}
                    />
                    {/* image preview */}
                    <img src={img.url} alt={img.name} />
                    {(selected === img.id || (img.active === '1' && selected === -1)) && (
                      // icon to view which image is selected
                      <FontAwesomeIcon icon={faCheckCircle} className="favIcon check" />
                    )}
                  </div>
                );
              })}
            </div>
          </Gallery>
        </div>
      </BackgroundContainer>
    </BodyContent>
  );
}

export default Background;
