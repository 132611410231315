import React, { useState, useEffect, useRef, Fragment } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import { selectCol } from '../../features/selectedCol/selectedColSlice';
import { ButtonIcon, ButtonWarper } from '../ActionsButton/ActionsButton.style';
import { confirmDialog } from 'primereact/confirmdialog';
import axiosInstance from '../../core/axiosInstance';
import './Table.css';
import { toast } from 'react-toastify';

const Table = (props: any) => {
  const dt = useRef(null);
  const [selectedCols, setSelectedCustomers] = useState<any>({});
  const [actionName, setActionName] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('effect');
    switch (actionName) {
      case 'solve':
        // console.log(selectedCols, actionName);
        confirmApprove('Are you solve this problem?');
        break;
      case 'edit':
        // console.log(selectedCols, actionName);
        break;
      case 'delete':
        // console.log(selectedCols, actionName);
        confirmDelete();
        break;

      default:
        // console.log('none');
        break;
    }
  }, [actionName]);

  const accept = async () => {
    try {
      const deletEle: any = await axiosInstance.delete(
        `/dashboard/action-logs/${selectedCols.id}`
      );
      const index: number = props.data.indexOf(selectedCols);
      props.data.splice(index, 1);
      toast.success(deletEle.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      // console.log('delete', deletEle);
    } catch (error) {
      // console.log(error);
    }
    setActionName('');
  };

  const reject = () => {
    // console.log('reject');
    setActionName('');
  };

  const confirmApprove = (message: string) => {
    confirmDialog({
      message: message,
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject,
    });
  };

  const confirmDelete = () => {
    confirmDialog({
      message: 'Are you sure that you want to delete this record?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept,
      reject,
    });
  };

  const problemSolved = () => {
    setActionName('solve');
  };
  const editProblem = () => {
    setActionName('edit');
  };
  const deleteProblem = () => {
    setActionName('delete');
  };

  const actionBtns = [
    {
      title: 'add',
      iconName: 'pi pi-check-circle',
      color: 'var(--primary-color)',
      command: problemSolved,
    },
    {
      title: 'edit',
      iconName: 'pi pi-pencil',
      color: 'var(--primary-color)',
      command: editProblem,
    },
    {
      title: 'delete',
      iconName: 'pi pi-trash',
      color: '#FF0000',
      command: deleteProblem,
    },
  ];
  const renderActions = () => {
    return (
      <ButtonWarper>
        {actionBtns.map((btn: any, index: number) => {
          if (!props.actions.includes(btn.title)) {
            return <Fragment key={index}></Fragment>;
          }
          return (
            <ButtonIcon
              key={index}
              className={btn.iconName}
              style={{
                color: btn.color,
              }}
              onClick={btn.command}
            ></ButtonIcon>
          );
        })}
      </ButtonWarper>
    );
  };

  const onSelection = async (e: any) => {
    dispatch(selectCol(e.value));
    setSelectedCustomers(e.value);
  };

  return (
    <div className="datatable-card">
      <div className="card">
        <DataTable
          ref={dt}
          value={props.data}
          className="coustom-table"
          dataKey="id"
          lazy
          // rowHover
          selectionMode="single"
          scrollable
          scrollHeight="60"
          selection={selectedCols}
          onSelectionChange={onSelection}
          emptyMessage="No Data Found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink  NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          {props.cols.map((col: any) => {
            return (
              <Column
                key={col.id}
                field={col.field}
                header={col.Name}
                sortable={col.sortable ? true : false}
                filter={col.filter ? true : false}
                filterPlaceholder={col.filterPlaceholder}
              />
            );
          })}

          <Column field="actions" body={renderActions} />
        </DataTable>
      </div>
    </div>
  );
};

export default Table;
