// import { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
// import { useAppDispatch, useAppSelector } from '../../../core/hooks';
// import axiosInstance from '../../../core/axiosInstance';
// import { openPage, selectPerview } from '../../../features/previewedPage/previewPageSlice';
import { ImgBC, MediaContainer, PreviewContainer, Slider, TextContainer } from '../../Preview/Preview.Style';
// import './perview.css'

const CreatePreview = (props: any) => {
  const selectedPage = props.data
  
  const images = props.data.media.length > 0 ? props.data.media.map((image: any, i: number) => {
    return (
      {
        original: image,
        thumbnail: image
      }
    )
  }) : {}

  const getVideolink = () => {
    let link = selectedPage?.media[0];
    let domain = link.split('.com')[0].split('//')[1];
    if (domain === 'vimeo') {
      var vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
      var parsed = link.match(vimeoRegex);
      link = "https://player.vimeo.com/video/" + parsed[1];
    }
    else {
      var videoId = link.split("?v=")[1]?.split('&')[0]; //id
      if (videoId) {
        link = "https://www.youtube.com/embed/" + videoId;
      }
    }
    return link;
  }

  return (
    (selectedPage.media?.length > 0) && (selectedPage.type === 1) ?
      <Slider>
        <ImgBC src={selectedPage.background} alt="" />
        <div className="overlay" />
        {images && console.log(images)
        }
        {images && <ImageGallery items={images} />}
      </Slider>
      :
      <PreviewContainer>
        <img src={selectedPage.background} alt="" />
        <div className="overlay">
          {selectedPage?.text && (
            <TextContainer>
              {
                selectedPage.text.template.path &&
                <img src={selectedPage.text.template.path} alt="temp" />
              }
              <div
                className="header"
                dangerouslySetInnerHTML={{ __html: selectedPage.text?.title }}
              ></div>
              <div className="t-body"
                dangerouslySetInnerHTML={{ __html: selectedPage.text?.body }}
              ></div>
            </TextContainer>
          )}
            {((selectedPage.media?.length > 0) && (selectedPage.type === 2)) &&
                (
                    <iframe
                    title="video_frame"
                    src={`${getVideolink()}?autoplay=1&loop=1&muted=1&controls=1`}
                    height="100%" width="100%"
                    frameBorder={0}
                    allow="autoplay; fullScreen"
                    allowFullScreen
                    style={{position:'absolute', top:'0', left:'0', bottom:'0', right:'0', width:'100%', height:'100%', border:'none', margin:'0', padding:'0', overflow:'hidden', zIndex: 999}}
                    />
                )
                        // getVideolink()
            }
          {((selectedPage.media?.length > 0) && (selectedPage.media[0]?.type_id === 1) && (selectedPage.media?.length === 1)) && (
            <MediaContainer>
              <img src={selectedPage.media[0]?.url} alt={selectedPage.media[0]?.name} width="100%"></img>
            </MediaContainer>
          )}
        </div>
      </PreviewContainer>
  );
};

export default CreatePreview;
