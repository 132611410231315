import axios from 'axios';

const axiosInstance = axios.create({
  baseURL:  process.env.REACT_APP_DATABASE_URL,
});

export default axiosInstance;
export const countVisitors = ( newEventId )=>{
    let EXPIRATION_DATE;
    if(localStorage.getItem("TODAY")){
        EXPIRATION_DATE = JSON.parse(localStorage.getItem("TODAY"))
    }else{
        EXPIRATION_DATE = {
            day:new Date().getDate()-1,
            month:new Date().getMonth(),
            year:new Date().getFullYear()
        }
    }
    const currentDate = new Date();// Current date 

    const dateChanges = EXPIRATION_DATE.day < currentDate.getDate()
    && EXPIRATION_DATE.month <= currentDate.getMonth()
    && EXPIRATION_DATE.year <= currentDate.getFullYear();
    if(newEventId && (dateChanges || newEventId != localStorage.getItem("EVENT_ID"))){
        // If today is after the expiry date
        // Send Incremental flag to the database;

        axiosInstance.post('/pages/visitors', {'event_id' : newEventId})
        // Set the storage to meet today's date
        EXPIRATION_DATE.day = new Date().getDate()
        localStorage.setItem("TODAY", JSON.stringify(EXPIRATION_DATE));
    }else{
        console.log("This Session Will not count");
    }
}

