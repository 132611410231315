
import logo from '../../assets/images/cadLogo.png';
import { Warper, Logo, Title, ForgotDiv, FomrDiv, Label } from './ForgotPassword.style';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom';
import { loadingSelector, setLoading } from '../../features/loading/loadingSlice';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../core/hooks';
import axiosInstance from '../../core/axiosInstance';

const ForgotPassword = () => {
  const defaultValues = {
    email: 'admin@admin.com',
  };
  //selectors
  const isLoading = useAppSelector(loadingSelector);
  const { control, handleSubmit } = useForm({ defaultValues });
  
  //store actions to store creadentials to the store
  const dispatch = useAppDispatch();

  //navigator to navigate to home if login sucess
  const navigate = useNavigate();

  const onSubmit = async (data:any) => {
    dispatch(setLoading(true));
    await axiosInstance.post<any>('/dashboard/forget-password', data)
      .then(() => {
        // console.log('success')
        navigate('/');
      })
      .catch((err: any) => {
        // console.log(err);
        dispatch(setLoading(false));
      });
  };

  return (
    <Warper>
      <Logo src={logo} alt="logo" />
      <ForgotDiv>
        <Title>Forgot Password</Title>
        <FomrDiv>
          <div className="p-d-flex p-jc-center" style={{ position: 'relative' }}>
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <div className="p-field">
                  <Label htmlFor="name">Email</Label>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: '*Required' }}
                    render={({ field, fieldState }) => (
                      <InputText
                        type="email"
                        disabled={isLoading ? true : false}
                        id={field.name}
                        {...field}
                        placeholder="Enter Your Email"
                        autoFocus
                      />
                    )}
                  />
                </div>
                
                <Button
                  type="submit"
                  label="Send Link"
                  className="p-mt-2 submit-btn"
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? (
                    <ProgressSpinner style={{ height: '1.4rem', textAlign: 'center' }} />
                  ) : null}
                </Button>
              </form>
            </div>
          </div>
          {/* <Mask /> */}
        </FomrDiv>
      </ForgotDiv>
    </Warper>
  );
};

export default ForgotPassword;
