// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDoRIDjw2lWhnJq_qrNQ8gYwbVwiJaa8kw",
  authDomain: "announcement-f94d6.firebaseapp.com",
  databaseURL: "https://announcement-f94d6-default-rtdb.firebaseio.com",
  projectId: "announcement-f94d6",
  storageBucket: "announcement-f94d6.appspot.com",
  messagingSenderId: "244407192652",
  appId: "1:244407192652:web:c52e48dcb7f486fd6d78e9",
  measurementId: "G-HHQ1WN6HQ1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
