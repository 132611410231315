import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import axiosInstance from '../../../core/axiosInstance';
import moment from 'moment';
import { Warper } from './Calender.style';
import { useAppDispatch } from '../../../core/hooks';
import { setLoading } from '../../../features/loading/loadingSlice';

const Calender = () => {
  const [events, setEvents] = useState([]);
  const dispatch = useAppDispatch()

  //stop loading once page load
  useEffect(() => { dispatch(setLoading(false)) }, [])

  //request events from calender end point
  useEffect(() => { getEvents() }, []);

  const getStatus: any = (obj: any) => {
    const daysBeforstart = new Date(obj.start_at);
    const startAt = moment(daysBeforstart);
    const daysBeforEnd = new Date(obj.expiry_at);
    const endAt = moment(daysBeforEnd);
    // define the status of the event and the color represent every status
    if (obj.status === 1) {
      if (moment().isBetween(startAt, endAt)) return 'green'
      if (startAt.isAfter(moment())) return '#0d89ec'
      if (endAt.isBefore(moment())) return 'red';
    } else {
      if (endAt.isBefore(moment())) return 'red'
      else {
        return 'orange'
      }
    }
  };

  const getEvents = async () => {
    const calenderEvents: any = await axiosInstance.get('dashboard/calendar');
    const mapedEvents = calenderEvents.data.data.map((event: any) => {
      const color = getStatus(event);
      return {
        title: event.name,
        start: event.start_at,
        end: event.expiry_at,
        color,
      };
    });
    setEvents(mapedEvents);
  };

  return (
    <Warper>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={events}
      />
      <span style={{ fontSize: '.8vw', position: 'absolute', color: '#5e0000', backgroundColor: '#ffa60045', bottom: '1vh', left: "2vw" }}>Note: *The invisible date and time are already blocked. Consider rescheduling</span>
    </Warper>
  );
};

export default Calender;
