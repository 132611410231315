import styled, { keyframes } from 'styled-components';

export const bounceFromUp = keyframes`
  0%{
    position: relative;
    top: -10px;
    opacity: 0;
  }100%{
    position: relative;
    top:0;
    opacity: 1;
  }
`;
const bounceFromleft = keyframes`
  0%{
    position: relative;
    left: -1rem;
    opacity: 0;
  }100%{
    position: relative;
    left:0;
    opacity: 1;
  }
`;

export const slideFromLeft = keyframes`
  0%{
    position: relative;
    left: -1rem;
    opacity:0;

  }
  100%{
    position: relative;
    left:0;
    opacity: 1;
  }
`;

export const Content = styled.div`
  position: relative;
  margin-right: 1rem;
  /* left: 12rem;
  top: 3rem; */
`;

export const DataTitle = styled.p`
  font-size: 2rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 0;
  animation: ${slideFromLeft} 1s ease-in-out;
`;

export const Separator = styled.p`
  height: 1px;
  width: 100%;
  margin: 1rem 0px;
`;

export const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: 500;
  margin: 1rem 0px;
  animation: ${bounceFromleft} 1s ease-in-out;
`;

export const BodyContent = styled.div`
  background-color: #fff;
  height: 78vh;
  border-radius: 20px;
  border: solid lightgrey 2px;
  padding: 2%;
  overflow: hidden;
  overflow-y: auto;
  margin-top: .5rem;
  animation: ${bounceFromUp} 1s ease-in-out;
`;
export const CellContent = styled.div`
  position: relative;
  height: 12vw;
  > img {
    position: absolute;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    background-color: black;
    border-radius: 10px;
  }
  > h5 {
    position: absolute;
    color: #fff;
    top: 0.5rem;
    right: 0.5rem;
    width: 25%;
    height: 2rem;
    text-align: center;
    margin: 0;
    padding: 0.5rem 0px;
    border-radius: 0 8px 0 8px;
    font-weight: 400;
  }
  > .overlay {
    position: absolute;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    border-radius: 10px;
    background-color: #08080899;
  }
  > .countDown{
  position: absolute;
  top: 1rem;
  left: 1.2rem;
  width: 65%;
  word-break: break-word;
  height: 5vw;
  overflow: auto;
  text-align: left;
  margin: 0;
  padding: 0.5rem 0px;
  border-radius: 0 8px 0 8px;
  font-weight: 400;
  font-size: 1vw;
  color: var(--primary-color);
  filter: brightness(1.6);
}
  > .controllBar {
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 1rem);
    border-radius: 10px;
    padding: 0 0.6rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    p {
      font-size: 1vw;
      color: #fff;
      max-width: 50%;
      margin: 0px;
      word-break: break-word;
    }
    .copy{
      :active{
        color: var(--primary-color);
      }
    }
  }
  .favIcon {
    margin: 0px 7px;
    font-size: 1vw;
    color: #fff;
    cursor: pointer;
  }
  .red {
    color: #ff0000;
  }
`;

export const DialogBody = styled.div`
  width: 100%;
  height: calc(100% - 3.5rem);
  position: absolute;
  top: 3.5rem;
  left: 0;
  .image-gallery-slides img{
    height: 70vh !important;
  }
`;

