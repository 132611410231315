import logo from '../../assets/images/logo2.svg';
import { LoginDiv, Warper, Logo, Title } from './Login.style';
import LoginForm from '../../components/LoginForm/LoginForm';

const Login = () => {
  return (
    <Warper>
      <Logo src={logo} alt="logo" />
      <LoginDiv>
        <Title>Login</Title>
        <LoginForm />
      </LoginDiv>
    </Warper>
  );
};

export default Login;
